import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import MainNav from "./mainnav"
import { masthead } from './header.module.css'

const Header = ({ siteTitle, menuLinks }) => (
  <header className={masthead} role="banner">
    <h1>
      <Link to="/">
          {siteTitle}
      </Link>
    </h1>
    <MainNav menuLinks={menuLinks}/>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
